import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

function Event(props) {
  const {
    path,
    subtitle,
    title,
    showIcon,
    colorBorder,
    date,
    id,
    className,
    categoryPk
  } = props

  return (
    <div
      className={`Event ${className}`}
      style={{
        borderLeft: `10px solid ${colorBorder}`,
      }}
    >
      <Link
        state={{ id, categoryPk }}
        className={`Event-link ${showIcon ? "icon" : ""}`}
        to={path}
      >
        <p className="Event-date">{date}</p>
        <p className="Event-subtitle">{subtitle}</p>
        <p className={`Event-title`}>{title}</p>
      </Link>
    </div>
  )
}

Event.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
  colorBorder: PropTypes.string,
}

Event.defaultProps = {
  showIcon: false,
  colorBorder: "#112149",
}

export default Event
